import Swiper from "swiper";

AOS.init({
  duration: 1000,
});

var burgerMenu = document.getElementById("burger-menu");
var overlay = document.getElementById("menu");

burgerMenu.addEventListener("click", function () {
  this.classList.toggle("close");
  overlay.classList.toggle("overlay");
});

overlay.addEventListener("click", function () {
  this.classList.remove("overlay");
  burgerMenu.classList.remove("overlay");
});

const date = new Date();
const year = date.getFullYear();
const yearAtFooter = document.getElementById("year");

if (year !== Number(yearAtFooter.textContent)) {
  yearAtFooter.textContent = year;
}

const swiperTestResults = new Swiper("#test-results", {
  spaceBetween: 50,

  breakpoints: {
    1025: {
      slidesPerView: 2,
    },
    320: {
      slidesPerView: 1,
    },
  },
});
